import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BookIcon from "@mui/icons-material/Book";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import CopyRight from "@mui/icons-material/Copyright";
import PaletteIcon from "@mui/icons-material/Palette";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Home() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"style={{
        minHeight: "100vh",
        backgroundColor: "rgb(220, 198, 157)",
        backgroundImage: "url(/background.svg)",
      }}
    >
      <Grid item xs={3} sx={{m:2}} justify="center" align="center">
        <Card sx={{ maxWidth: 400 }}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="100%"
            image="/manoochehri.jpg"
          />
          <CardContent background="royalblue">
            <Typography gutterBottom variant="h5" component="div">
              منوچهری دامغانی
            </Typography>
            <Typography variant="body2" color="text.secondary">
              اَبوالنَّجم احمد بن قوس بن احمد منوچهری دامغانی معروف به منوچهری
              از شعرای طراز اول قرن پنجم هجری و اهل دامغان بود اما دولتشاه
              زادگاه او را بلخ دانسته است.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="outlined"
              sx={{ width: "100%" }}
              target="_blank"
              onClick={handleClickOpen}
              size="small"
            >
              بیشتر بدانید
            </Button>
          </CardActions>
        </Card>

        <Button
          sx={{ mt: 3, width: "100%", maxWidth: 400 }}
          variant="contained"
          href="/divan"
          size="small"
          startIcon={<BookIcon />}
        >
          مشاهده‌ی دیوان اشعار
        </Button>

        <Button
          sx={{ mt: 3, width: "100%", maxWidth: 400 }}
          variant="contained"
          href="/moshaere"
          size="small"
          startIcon={<KeyboardVoiceIcon />}
        >
          کمک مشاعره
        </Button>

        <Button
          sx={{ mt: 3, width: "100%", maxWidth: 400 }}
          variant="contained"
          href="/colors"
          size="small"
          startIcon={<PaletteIcon />}
        >
          عنصر رنگ
        </Button>

        <Button
          sx={{ mt: 3, mb: 3, width: "100%", maxWidth: 400 }}
          variant="contained"
          size="small"
          endIcon={<CopyRight />}
        >
          کاری از امیررضا شمس
        </Button>
      </Grid>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>درباره‌ی منوچهری دامغانی</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <h3>مقدمه</h3>
            <p>
              اَبوالنَّجم احمد بن قوس بن احمد منوچهری دامغانی (در گذشته به سال
              ۴۳۲ هجری) معروف به منوچهری از شعرای طراز اول قرن پنجم هجری و اهل
              دامغان بود اما دولتشاه مولد او را بلخ دانسته است . در کتب تذکره او
              را ((شصت گله )) و ((شصت کُله)) لقب داده اند . گویا علت اشتهار
              منوچهری با این لقب در نزد تذکره نویسان، اشتباه این شاعرست با ((
              الدین احمد بن منوچهر شصت کُله )) که از شعرای قرن ششم بود .
            </p>
            <p>
              تخلص خود را از نام نخستین ممدوح خود فلک‌المعالی منوچهر بن قابوس بن
              وُشمگیر پنجمین حکمران زیاری گرفته‌است. بازتاب عناصر طبیعی نظیر
              رنگ، صوت و عناصر بویایی در آثار منوچهری چشمگیر است. هرچند منوچهری
              اولین سرایندهٔ ایرانی نیست که از سرایندگان عرب متأثر شده‌است، با
              وجود این، شاید در میان سرایندگان فارسی‌زبانِ پیش از خود یا
              معاصرانش هیچ کس به اندازهٔ او از ادبیات عرب تأثیر نپذیرفته باشد.
            </p>
            <h3>زندگی</h3>
            <p>
              از کودکی و جوانی منوچهری در دامغان اطلاعات چندانی در دست نیست جز
              آنکه عوفی نوشته است (( در ایام کودکی چنان ذکی بود که هر نوع ازو در
              شعر امتحان کردندی بدیهه بگفتی و خاطر او بمواتات آن مسامحه نکردی ))
              با توجه به سیاق متداول عصر و با توجه به وجود چندین حوزه علمیه و
              مدرسه علوم دینی در دامغان که قدمت برخی از آنها به زمانی پیش از عصر
              منوچهری می رسد؛ احتمالا وی تحصیلات آغازین خود را در یکی از همین
              مدارس دینی گذرانده باشد که این امر می تواند سرآغاز آشنایی منوچهری
              با زبان و ادبیات عربی باشد. دولتشاه در تذکرت الشعرا و هدایت در
              مجمع الفصحا به نقل از میر محمد تقی کاشانی در خلاصت الافکار نوشته
              اند که منوچهری شاگرد ابوالفرج سگزی شاعر معروف اواخر قرن چهارم بوده
              است . پس از آن وی به خدمت منوچهر قابوس زیاری در طبرستان رسید. پس
              از مرگ منوچهر قابوس، منوچهری به ری رفت و به خدمت طاهر دبیر رسید که
              از طرف سلطان مسعود غزنوی در آن‌جا فرمانروایی داشت. وی از آنجا به
              دربار غزنه راه یافته، و به ستایشگری سلطان مسعود غزنوی مشغول شد.
              منوچهری بر اثر جوانی ، جودت ذهن و شیرینی زبانی در خدمت مسعود
              دستگاهی داشت و از این رو محسود اقران بود . منوچهری برای جلب حمایت
              عنصری قصیده ای به نام «لغز شمع» سرود و در آن عنصری را ستایش کرد.
              در سال ۴۳۲ هجری قمری، منوچهری در حالی که سی و چهار سال داشت
              درگذشت.
            </p>
            <h3>وضعیت تصحیح و ویرایش دیوان منوچهری</h3>
            <p>
              از دیوان منوچهری با همه اشتهار و تداول اشعارش نسخ قدیم در دست نیست
              و غالب نسخ موجود آن از عهد قاجاریه و قدیم ترین آن از دوره صفویه
              است . در همه این نسخ اشعار شیوا و دشوار شاعر دچار تصرفات نساخ شده
              و پر از غلط است به جز نسخه ای که توسط محمد دبیرسیاقی منتشر شد که
              آخرین تجدید نظر وی از این دیوان در سال ۱۳۸۷ به وسیلهٔ انتشارات
              زوّار چاپ شده‌است. تصحیح دیگری توسط برات زنجانی توسط مؤسسه
              انتشارات دانشگاه تهران در سال ۱۳۸۷ چاپ شد. در سال ۱۳۹۰ دیوان
              منوچهری دامغانی به تصحیح عزیزالله علیزاده در تهران توسط انتشارات
              فردوس در قطع وزیری و ۳۳۶ صفحه شامل قصاید، قطعات، مُسَمّطات،
              رباعیّات، دوبیتی و ابیات دیگر، کشف الابیات، همراه با وزن و بحر
              تمام اشعار منتشر شده‌‌است. در سال ۱۳۹۳ دیوان منوچهری دامغانی به
              تصحیح حبیب یغمایی و به کوشش سید علی آل داود از سوی بنیاد موقوفات
              دکتر محمود افشار منتشر شده‌است.
            </p>
            <b>منبع: ویکی‌پدیا</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>بستن</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
