import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import data from "../Data/Color.json";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Colors() {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "rgb(220, 198, 157)",
        backgroundImage: "url(/background.svg)",
      }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Card sx={{ maxWidth: 700, m: 2, mt: 4 }}>
          <CardContent background="royalblue">
            <Typography gutterBottom variant="h5" component="div">
              پیش‌گفتار
            </Typography>
            <Typography variant="body2" color="text.secondary">
              شاعران با استفاده از قدرت رنگ‌ها مهر و خشم، ترس و عواطف خود را
              نشان می‌دهند و تأثیر رنگ تا جایی است که به خوانش جریان ذهنی و
              حالات و عواطف روحی شاعر کمک می کند. رنگ‌ها در متون ادبی از
              فضای روح و روان و ذهن، بر زبان شاعر جاری می‌شوند، به
              عبارتی رنگ‌ها بازتاب انگیزه‌های درونی شاعر و گزارش‌دهنده‌ی محتوای ذهن او هستند. 
              <br/>
              <b> منوچهری دامغانی</b> با مشخصه‌ی ویژه‌ی طبیعت‌گرایی از جمله
              شاعرانی است که رنگ در دیوان اشعار او جایگاهی خاص دارد. رنگ آبی،
              زرد، سرخ، سیاه، لاجوردی، و ... از جمله رنگ‌هایی هستند که در اشعار
              این شاعر جلوه‌ای خاص دارند و هر یک بیانگر بخشی از فضای ذهنی و
              روانی شاعر هستند.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              در این قسمت برنامه شما می‌توانید رنگ‌های بکار رفته در دیوان
              منوچهری و ابیاتی را که شامل آن‌هاست مشاهده کنید.
            </Typography>
            <img alt="chart" width={"100%"} src="/color_chart.png" />
            <Button
              href="/"
              size="small"
              variant="contained"
              sx={{ width: "100%", mt: 1 }}
            >
              برگشت
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        sx={{ pb: 2 }}
      >
        {data.map((color) => (
          <Accordion sx={{ maxWidth: 700, mt: 2, width: "95%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Box
                sx={{
                  p: 1,
                  width: "25px",
                  height: "25px",
                  background:
                    color.code.length > 1
                      ? `linear-gradient(${color.code.join(",")})`
                      : color.code[0],
                  border: color.code.length > 0 ? "1px solid gray" : "none",
                  display: color.code.length > 0 ? "inline" : "none",
                  borderRadius: "17px",
                }}
              ></Box>
              <Typography sx={{ p: 1, width: "100%" }}>{color.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography align="center">
                {/* لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                  استفاده از طراحان گرافیک است. لورم ایپسوم متن ساختگی با تولید
                  سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                  استفاده از طراحان گرافیک است. */}
              </Typography>

              <hr />
              {color.beits.map((beit) => (
                <Card sx={{ maxWidth: 700, width: "90%", m: 2 }}>
                  <CardContent background="royalblue">
                    <Typography
                      sx={{ textAlign: "center" }}
                      variant="body2"
                      color="text.secondary"
                    >
                      {beit}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </div>
  );
}
