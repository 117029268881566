import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import data from "../Data/Poems.json";

export default function Divan() {
  const [search, setSearch] = React.useState("");
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "rgb(220, 198, 157)",
        backgroundImage: "url(/background.svg)",
      }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Card sx={{ maxWidth: 700, m: 2, mt: 4 }}>
          <CardContent background="royalblue">
            <Typography gutterBottom variant="h5" component="div">
              دیوان اشعار
            </Typography>
            <Typography variant="body2" color="text.secondary">
            دیوان منوچهری مشتمل بر اشعاری است که در قالب غزل، قصیده، مسمط، قطعه، و ترکیب‌بند سروده شده و موضوعاتی چون ستایش، وصف و خمریه را در بر می‌گیرد. منوچهری قالب مسمط را برای نخستین بار در شعر پارسی پدید آورده است زیرا پیش از او در اشعار فارسی اثری از آن نمی یابیم . از میان شاعران بعد از منوچهری لامعی گرگانی کار او را در سرودن مسمط دنبال کرد . اشعار او معمولاً در دو سبک می‌باشد. یا تغزل و اشعاری که به جوانی او هنگام شاعری باز می‌گردد و دیگری مدح و ستایش سلاطین و بزرگان زمانه که از رسوم معمول شاعری آن زمان بوده‌است. در اشعار او مفردات و اصطلاحات عربی بسیار دیده می‌شود که توانایی او در ادبیات عرب را منعکس می‌سازد. عادت منوچهری باظهار علم باعث استعمال لغات و اصطلاحات مهجور عربی در شعر او شده و گاه آن را بخشونت و درشتی مقرون ساخته است لیکن در عین حال باید اذعان داشت که حتی آن اشعار که با چنین ترکیباتی به وجود آمده است نیز جذابیت و شکوه خاصی دارد .             </Typography>
            <Button
              href="/"
              size="small"
              variant="contained"
              sx={{ width: "100%", mt: 1 }}
            >
              برگشت
            </Button>
          </CardContent>
        </Card>
      </Grid>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        sx={{ p: 2 }}
      >
        <TextField
          sx={{ maxWidth: 700, mt: 2, width: "95%" }}
          text={search}
          onChange={(e) => setSearch(e.target.value)}
          label="جستجو"
          variant="filled"
        />
        {data
          .filter((a) => a.text.includes(search) || !search)
          .map((a) => (
            <Accordion sx={{ maxWidth: 700, mt: 2, width: "95%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{a.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  align="center"
                >
                  {a.text
                      .split("|")
                      .map((b) => b.includes(search)&&search?<p><mark>{b}</mark></p>:<p>{b}</p>)}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
      </Grid>
    </div>
  );
}
