import * as React from "react";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Alpha from "../Data/Alpha.json";

export default function Moshaere() {
  const [alphabet, setAlphabet] = React.useState("ا");
  const [count, setCount] = React.useState(5);
  const [random, setRandom] = React.useState(true);
  const handleClick = () => {};
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "rgb(220, 198, 157)",
        backgroundImage: "url(/background.svg)",
      }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Card sx={{ maxWidth: 700, mt: 4, m: 2 }}>
          <CardContent background="royalblue">
            <Typography gutterBottom variant="h5" component="div">
              کمک مشاعره
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <p>
                در این بخش شما می‌توانید ابیات دیوان منوچهری را بر اساس حرف اول به تعداد مشخص مشاهده کنید.
              </p>
              <Box>
                <FormControl fullWidth sx={{ mb: 1 }}>
                  <InputLabel id="demo-simple-select-label">حرف</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={alphabet}
                    label="حرف"
                    onChange={(event) => {
                      setAlphabet(event.target.value);
                    }}
                  >
                    {[
                      "آ",
                      "ا",
                      "ب",
                      "پ",
                      "ت",
                      "ث",
                      "ج",
                      "چ",
                      "ح",
                      "خ",
                      "د",
                      "ذ",
                      "ر",
                      "ز",
                      "ژ",
                      "س",
                      "ش",
                      "ص",
                      "ض",
                      "ط",
                      "ظ",
                      "ع",
                      "غ",
                      "ف",
                      "ق",
                      "ک",
                      "گ",
                      "ل",
                      "م",
                      "ن",
                      "و",
                      "ه",
                      "ی",
                    ].map((alpha) => (
                      <MenuItem value={alpha}>{alpha}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  type="number"
                  id="outlined-basic"
                  label="تعداد"
                  variant="outlined"
                  value={count}
                  onChange={(e)=>setCount(e.target.value)}
                />
                <FormGroup>
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="تصادفی"
                    checked={random}
                    onChange={(e)=>setRandom(e.target.checked)}
                  />
                </FormGroup>

                <Button
                  href="/"
                  sx={{ mt: 3, width: "100%", maxWidth: 700 }}
                  variant="contained"
                  onClick={handleClick}
                >
                  بازگشت
                </Button>
              </Box>
            </Typography>
          </CardContent>
        </Card>
        {(random?Alpha[alphabet].sort( () => .5 - Math.random() ):Alpha[alphabet]).slice(0,count).map((i) => (
          <Card sx={{ maxWidth: 700, width: "90%", m: 2 }}>
            <CardContent background="royalblue">
              <Typography
                sx={{ textAlign: "center" }}
                variant="body2"
                color="text.secondary"
              >
                {i}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Grid>
    </div>
  );
}
