import { Routes, Route  } from "react-router-dom";
import Home from "./Pages/Home";
import Color from "./Pages/Color";
import Divan from "./Pages/Divan";
import Redirect from "./Components/Redirect";
import Moshaere from "./Pages/Moshaere";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { brown } from "@mui/material/colors";
import createCache from "@emotion/cache";
const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      main: brown[500],
    },
  },
});

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin],
});
function App() {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>

        <div style={{ marginTop: "" }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/divan" element={<Divan />} />
            <Route path="/moshaere" element={<Moshaere />} />
            <Route path="/colors" element={<Color />} />
            <Route path="*" element={<Redirect to="/"/>} />
          </Routes>
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
