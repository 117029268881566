import { useEffect } from "react";
import { useNavigate } from "react-router";

export default function Redirect ({ to }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return <div></div>;
};
